SalaryCtrl.$inject = [
  "$scope",
  "$filter",
  "timeTravelMoment",
  "Salary",
  "KompBank",
  "KompBank2",
  "FortNoxSalary",
  "FortNoxAbsence",
  "moment",
  "Salla",
  "Aalla",
  "FortNoxKomp",
];

export default function SalaryCtrl(
  $scope,
  $filter,
  timeTravelMoment,
  Salary,
  KompBank,
  KompBank2,
  FortNoxSalary,
  FortNoxAbsence,
  moment,
  Salla,
  Aalla,
  FortNoxKomp
) {
  $scope.formData = {};
  $scope.formData.dateTo = null;
  $scope.formData.dateFrom = null;
  $scope.opened1 = false;
  $scope.opened2 = false;

  var numberFormat = new Intl.NumberFormat("sv-SE", {
    useGrouping: false,
    maximumFractionDigits: 2,
  });

  //Datepicker
  $scope.dateOptions = {
    "year-format": "'yy'",
    "show-weeks": true,
    "starting-day": 1,
  };

  $scope.message = "Löneunderlag";

  $scope.header = ["ID", "Namn", "Summa"];

  $scope.custMode = { external: true, internal: true, absense: true };
  $scope.groupMode = "T";
  $scope.timeMode = "A";
  $scope.sumMode = "A";

  if (!$scope.formData.dateFrom) {
    if (moment().date() > 25) {
      $scope.momentStart = moment(
        moment().startOf("day").startOf("month").format("YYYY-MM-DD")
      );
    } else {
      $scope.momentStart = moment(
        moment()
          .startOf("day")
          .startOf("month")
          .add(-1, "month")
          .format("YYYY-MM-DD")
      );
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
  }

  if (!$scope.formData.dateTo) {
    $scope.momentStop = moment($scope.momentStart)
      .add(1, "month")
      .subtract(1, "days");
    $scope.formData.dateTo = $scope.momentStop.toDate();
  }

  $scope.addKomp = function (inv, ind) {
    //console.log(inv);

    if (inv[12] > 0) {
      var dd = 1;
      if (inv[12] < 1) {
        dd = inv[12];
      }
      inv[12] -= dd;
      inv[22] += dd;
      inv[20] = numberFormat.format(
        (inv[12] *
          1 *
          ($scope.invoice[ind].salary + $scope.invoice[ind].bonus)) /
          175
      );
      if (typeof $scope.karka === "undefined") {
        $scope.karka = [];
      }
      if (typeof $scope.karka[ind] === "undefined") {
        $scope.karka[ind] = [];
      }
      $scope.karka[ind][12] = true;
      $scope.karka[ind][20] = true;
      $scope.karka[ind][22] = true;
    }
  };

  $scope.addKomp2 = function (inv, ind) {
    //console.log(inv);

    if (inv[13] > 0) {
      var dd = 1;
      if (inv[13] < 1) {
        dd = inv[13];
      }
      inv[13] -= dd;
      inv[22] += dd;
      var timp =
        (1.5 * ($scope.invoice[ind].salary + $scope.invoice[ind].bonus)) / 175;
      var kojjj = inv[13] * timp;
      //console.log(timp, kojjj);
      inv[21] = numberFormat.format(kojjj);
      if (typeof $scope.karka === "undefined") {
        $scope.karka = [];
      }
      if (typeof $scope.karka[ind] === "undefined") {
        $scope.karka[ind] = [];
      }
      $scope.karka[ind][13] = true;
      $scope.karka[ind][21] = true;
      $scope.karka[ind][22] = true;
    }
  };

  $scope.delKomp = function (inv) {
    //console.log(inv);
  };

  $scope.getHeader = function () {
    return $scope.header;
  };

  $scope.koko = function () {
    //console.log($scope.invoice);
    return $scope.expat;
  };

  $scope.workDays = function (start, stop) {
    var workHours = 0;
    for (var day = start; day <= stop; day.add(1, "days")) {
      if (timeTravelMoment.isRedDayMoment(day) !== undefined) {
        //console.log('röd dag ',day);
        continue;
      }
      if (timeTravelMoment.isWeekendDay(day)) {
        //console.log('helg dag ',day);
        continue;
      }
      workHours += 8;
    }
    //console.log(workHours);
    return workHours;
  };

  $scope.getKomp2 = function (employee_id, stop_date) {
    return KompBank2.get(
      {
        employee_id: employee_id,
        stop_date: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        return data[0];
      },
      function (error) {
        return [{ hours: 0 }];
      }
    );
  };

  $scope.getKomp = function (employee_id, stop_date) {
    var kompTimmar = {
      work: 0,
      tot: 0,
      komp: 0,
      over: 0,
      kompsaldo: 0,
    };

    KompBank.get(
      {
        employee_id: employee_id,
        stop_date: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        var kjk = data[0];
        //console.log(kjk);

        $scope.kompp = kompTimmar;
        var start;
        try {
          start = moment(new Date(kjk.balance_date));
        } catch (e) {
          return;
        }
        var stop = $scope.momentStop;

        if (start.isAfter(stop)) {
          return;
        }

        //console.log(kjk, start.add(1, 'days').format('YYYY-MM-DD'), stop.format('YYYY-MM-DD'));
        kjk.workHours = $scope.workDays(start, stop);

        /*
                     balance_date
                     diff
                     hours
                     komput
                     newKomput
                     over
                     overtid
                     tot
                     workHours
                     */

        var tot = kjk.tot + kjk.komput;
        var overtid = tot - kjk.workHours;
        var mko = Math.min(kjk.komput, overtid);
        var nkomp = Math.max(kjk.komput - overtid, 0);
        var novertid = overtid - mko;
        var nkompsaldo = kjk.hours - nkomp + novertid;

        kompTimmar = {
          work: kjk.workHours,
          tot: kjk.tot,
          komp: nkomp,
          over: novertid,
          kompsaldo: nkompsaldo,
        };
        $scope.kompp = kompTimmar;
      },
      function (err) {
        kompTimmar = {
          work: 0,
          tot: 0,
          komp: 0,
          over: 0,
          kompsaldo: 0,
        };
      }
    );
  };

  $scope.updatePeriod = function (dir) {
    $scope.momentStart = moment($scope.formData.dateFrom, "YYYY-MM-DD");
    $scope.momentStop = moment($scope.formData.dateTo, "YYYY-MM-DD");
    if (dir === -1) {
      $scope.momentStart.startOf("month").subtract(1, "months");
      $scope.momentStop.startOf("month").subtract(1, "days");
    } else {
      $scope.momentStart.startOf("month").add(1, "months");
      $scope.momentStop.startOf("month").add(2, "months").subtract(1, "days");
    }
    $scope.formData.dateFrom = $scope.momentStart.toDate();
    $scope.formData.dateTo = $scope.momentStop.toDate();
  };

  $scope.kaka = function (head, inv, index, row) {
    if (!$scope.exportData[row]) {
      $scope.exportData[row] = [];
    }
    switch (head) {
      case "Kompsaldo":
        $scope.exportData[row][index] =
          inv.kompppp.length > 0 ? inv.kompppp[0].hours : 0;
        break;
      case "Summa":
        $scope.exportData[row][index] = inv.sum;
        break;
      case "Kund":
        $scope.exportData[row][index] = inv.customername;
        break;
      case "Projekt":
        $scope.exportData[row][index] = inv.project_name;
        break;
      case "Aktivitet":
        $scope.exportData[row][index] = inv.activity_name;
        break;
      case "Timmar":
        $scope.exportData[row][index] = inv.time;
        break;
      case "Datum":
        $scope.exportData[row][index] = inv.reporteddate;
        break;
      case "Namn":
        $scope.exportData[row][index] = inv.name;
        break;
      case "Arbetat":
        /*
                         komp_ut = inv.komp_ut ? (inv.komp_ut):0;
                         var tot = inv.tot ? (inv.tot):0;
                         if (tot+komp_ut > inv.periodlength) {
                         komp_ut = inv.periodlength - tot;
                         }
                         komp_ut = Math.max(komp_ut,0);
                         */
        $scope.exportData[row][index] = inv.tot - inv.komp_ut;
        break;
      case "ID":
        $scope.exportData[row][index] = inv.employee_number;
        break;
      case "Normaltid":
        $scope.exportData[row][index] = inv.periodlength;
        break;
      case "Komp uttag":
        $scope.exportData[row][index] = inv.komp_ut;
        break;
      case "Diff":
        inv.diff = inv.tot - inv.periodlength;
        $scope.exportData[row][index] = inv.diff;
        break;
      case "Bonus":
        $scope.exportData[row][index] = inv.bonus;
        break;
      case "Bonus grund":
        inv.bonusgr =
          inv.debit +
          inv.int_komp +
          inv.int_proj +
          inv.internt +
          inv.oh +
          inv.semester +
          inv.komp_ut;
        $scope.exportData[row][index] = inv.bonusgr ? inv.bonusgr : 0;
        break;
      case "Mertid":
        inv.mertid = Math.min(inv.tjl, inv.diff)
          ? Math.min(inv.tjl, inv.diff)
          : 0;
        $scope.exportData[row][index] = inv.mertid;
        break;
      case "Mertid ers":
        $scope.exportData[row][index] =
          (inv.mertid * 1 * (inv.salary + inv.bonus)) / 175;
        break;
      case "Övertid ers":
        $scope.exportData[row][index] =
          (inv.overtid.curr * 1.5 * (inv.salary + inv.bonus)) / 175;
        break;
      case "Övertid":
        inv.overtid = {
          curr: inv.diff - inv.mertid,
          index: index,
          row: row,
        };

        $scope.exportData[row][index] = inv.overtid.curr;
        break;
      case "Ej Bonus":
        inv.ejbonus = inv.idle + inv.sjuk + inv.tjl;
        $scope.exportData[row][index] = inv.ejbonus ? inv.ejbonus : 0;
        break;
      case "Beläggning":
        inv.bel = Math.min(inv.bonusgr / inv.periodlength, 1);
        $scope.exportData[row][index] = inv.bel;
        break;
      case "Semester":
        $scope.exportData[row][index] = inv.semester / 8;
        break;
      case "Sem Bonus":
        var semBonus = inv.bonus * 0.14 * (inv.semester / 175);
        $scope.exportData[row][index] = semBonus;
        break;
      case "Lön":
        $scope.exportData[row][index] = inv.salary;
        break;
      case "Lönebonus":
        inv.totBonus = inv.bel * inv.bonus;
        $scope.exportData[row][index] = inv.totBonus;
        break;
      case "Tot bonus":
        $scope.exportData[row][index] = inv.totBonus + semBonus;
        break;
      default:
        break;
    }
    if (!isNaN($scope.exportData[row][index])) {
      $scope.exportData[row][index] =
        Math.round($scope.exportData[row][index] * 100) / 100;
      return numberFormat
        .format($scope.exportData[row][index])
        .replace("−", "-");
    } else {
      return $scope.exportData[row][index];
    }
  };

  $scope.kaka22 = function (header, dataIn, dataOut) {
    var timlön, sjuklön, karenstimmar, sjuktimmar, karensavdrag, sjuklöneavdrag;
    header.forEach(function (curr, index, arr) {
      switch (curr) {
        case "Kompsaldo":
          dataOut[index] = dataIn.kompbank;
          /*
              dataIn.pattt.$promise
              .then(function (result) {
                dataOut[index] = result[0].hours;
              })
              .catch(function (response) {
                dataOut[index] = 0;
              });
            */
          break;
        case "Summa":
          dataOut[index] = dataIn.sum;
          break;
        case "Kund":
          dataOut[index] = dataIn.customername;
          break;
        case "Projekt":
          dataOut[index] = dataIn.project_name;
          break;
        case "Aktivitet":
          dataOut[index] = dataIn.activity_name;
          break;
        case "Timmar":
          dataOut[index] = dataIn.time;
          break;
        case "Datum":
          dataOut[index] = dataIn.reporteddate;
          break;
        case "Namn":
          dataOut[index] = dataIn.name;
          break;
        case "Arbetat":
          /*
                             komp_ut = inv.komp_ut ? (inv.komp_ut):0;
                             var tot = inv.tot ? (inv.tot):0;
                             if (tot+komp_ut > inv.periodlength) {
                             komp_ut = inv.periodlength - tot;
                             }
                             komp_ut = Math.max(komp_ut,0);
                             */
          dataOut[index] = dataIn.tot - dataIn.komp_ut;
          break;
        case "ID":
          dataOut[index] = dataIn.employee_number;
          break;
        case "Normaltid":
          dataOut[index] = dataIn.periodlength;
          break;
        case "Komp uttag":
          dataOut[index] = dataIn.komp_ut;
          break;
        case "Diff":
          dataIn.diff = dataIn.tot - dataIn.periodlength;
          dataOut[index] = dataIn.diff;
          break;
        case "Bonus":
          dataOut[index] = dataIn.bonus;
          break;
        case "Bonus grund":
          dataIn.bonusgr =
            dataIn.debit +
            dataIn.int_komp +
            dataIn.int_proj +
            dataIn.internt +
            dataIn.oh +
            dataIn.semester +
            dataIn.komp_ut +
            dataIn.sjuk;
          dataOut[index] = dataIn.bonusgr ? dataIn.bonusgr : 0;
          break;
        case "Mertid":
          dataIn.mertid = Math.min(dataIn.tjl, dataIn.diff)
            ? Math.min(dataIn.tjl, dataIn.diff)
            : 0;
          dataOut[index] = dataIn.mertid;
          break;
        case "Mertid ers":
          dataOut[index] =
            (dataIn.mertid * 1 * (dataIn.salary + dataIn.bonus)) / 175;
          break;
        case "Övertid ers":
          dataOut[index] =
            (dataIn.overtid * 1.5 * (dataIn.salary + dataIn.bonus)) / 175;
          break;
        case "Övertid":
          dataIn.overtid = dataIn.diff - dataIn.mertid;

          dataOut[index] = dataIn.overtid;
          break;
        case "Ej Bonus":
          //dataIn.ejbonus = dataIn.idle + dataIn.sjuk + dataIn.tjl;
          dataIn.ejbonus = dataIn.idle + dataIn.tjl;
          dataOut[index] = dataIn.ejbonus ? dataIn.ejbonus : 0;
          break;
        case "Beläggning":
          dataIn.bel = Math.min(dataIn.bonusgr / dataIn.periodlength, 1);
          dataOut[index] = dataIn.bel;
          break;
        case "Semester":
          dataOut[index] = dataIn.semester / 8;
          break;
        case "Sem Bonus":
          var semBonus = dataIn.bonus * 0.14 * (dataIn.semester / 175);
          dataOut[index] = semBonus;
          break;
        case "Lön":
          dataOut[index] = dataIn.salary;
          break;
        case "Lönebonus":
          dataIn.totBonus = dataIn.bel * dataIn.bonus;
          if (dataIn.sjuk > 0) {
            karenstimmar = dataIn.sjuk - Math.max(dataIn.sjuk - 8, 0);
            sjuktimmar = Math.max(dataIn.sjuk - 8, 0);

            timlön = (dataIn.bonus * 12) / (52 * 40);
            sjuklön = 0.8 * timlön;

            karensavdrag = sjuklön * karenstimmar;
            sjuklöneavdrag = (timlön - sjuklön) * dataIn.sjuk;

            console.log(
              `${dataIn.name} Antal timmar sjuk ${dataIn.sjuk}, Karens ${karenstimmar}, Resten vanligt sjuk ${sjuktimmar}`
            );

            console.log(
              `Lön / h ${timlön}, Sjuklön / h ${sjuklön}, Sjuklönavdrag ${
                timlön - sjuklön
              } * ${
                dataIn.sjuk
              } = ${sjuklöneavdrag}, Karensavdrag ${sjuklön} * ${karenstimmar} = ${karensavdrag}`
            );

            dataIn.totBonus = dataIn.totBonus - sjuklöneavdrag - karensavdrag;
          }
          dataOut[index] = dataIn.bel * dataIn.bonus;
          break;
        case "Sjuk":
          dataOut[index] = dataIn.sjuk;
          break;
        case "Sjukavdrag":
          if (dataIn.sjuk > 0) {
            timlön = (dataIn.bonus * 12) / (52 * 40);
            sjuklön = 0.8 * timlön;
            dataOut[index] = -(timlön - sjuklön) * dataIn.sjuk;
          }
          break;
        case "Karensavdrag":
          if (dataIn.sjuk > 0) {
            timlön = (dataIn.bonus * 12) / (52 * 40);
            sjuklön = 0.8 * timlön;
            karenstimmar = dataIn.sjuk - Math.max(dataIn.sjuk - 8, 0);

            dataOut[index] = -sjuklön * karenstimmar;
          }
          break;
        case "Tot bonus":
          dataOut[index] =
            dataIn.totBonus + dataIn.bonus * 0.14 * (dataIn.semester / 175);
          break;
        default:
          break;
      }
      if (!isNaN(dataOut[index])) {
        dataOut[index] = Math.round(dataOut[index] * 100) / 100;
        return numberFormat.format(dataOut[index]).replace("−", "-");
      } else {
        return dataOut[index];
      }
    });
  };

  $scope.lockAbsence = function () {
    Aalla.get({}, function (resp) {
      console.log(resp);
    });
  };

  $scope.lockSalary = function () {
    Salla.get({}, function (resp) {
      console.log(resp);
    });
  };

  $scope.lockSalary1 = function () {
    var sal_statement = {
      salary: $scope.invoice,
      abcense: $scope.abscenseDetails,
    };

    Salary.save({ data: JSON.stringify(sal_statement) }, function (resp) {
      console.log(resp);
      /*
        Salary.lock(
          {
            id: data.id,
            from: $scope.salaryDates.start.format("YYYY-MM-DD"),
            to: $scope.salaryDates.stop.format("YYYY-MM-DD")
          },
          function(resp) {
            console.log(resp);
          }
        );
        */
    });
  };

  $scope.fetchSalary = function () {
    $scope.showAbscenseDetails = false;
    $scope.exportData = [];
    $scope.invoice = [];
    $scope.abscenseDetails = [];
    $scope.karka = [];
    //spinnerService.show('salaryReportSpinner1');
    switch ($scope.groupMode) {
      case "KU":
        $scope.header = ["ID", "Namn", "Kund", "Summa"];
        break;
      case "A":
        $scope.header = ["ID", "Namn", "Kund", "Projekt", "Aktivitet", "Summa"];
        break;
      case "P":
        $scope.header = ["ID", "Namn", "Kund", "Projekt", "Summa"];
        break;
      case "K":
        $scope.header = [
          "ID",
          "Namn",
          "Datum",
          "Kund",
          "Projekt",
          "Aktivitet",
          "Timmar",
        ];
        break;
      case "T":
        $scope.header = [
          "ID",
          "Namn",
          "Lön",
          "Bonus",
          "Normaltid",
          "Arbetat",
          "Komp uttag",
          "Sjuk",
          "Diff",
          "Bonus grund",
          "Ej Bonus",
          "Semester",
          "Mertid",
          "Övertid",
          "Beläggning",
          "Sjukavdrag",
          "Karensavdrag",
          "Sem Bonus",
          "Lönebonus",
          "Tot bonus",
          "Mertid ers",
          "Övertid ers",
          "Kompsaldo",
        ];
        $scope.header2 = [
          "ID",
          "Namn",
          "Datum",
          "Kund",
          "Projekt",
          "Aktivitet",
          "Timmar",
        ];
        break;
      default:
        break;
    }

    $scope.momentStart = moment($scope.formData.dateFrom);
    $scope.momentStop = moment($scope.formData.dateTo);

    $scope.salaryDates = {
      start: moment($scope.momentStart),
      stop: moment($scope.momentStop),
    };

    var workHours = 0;

    for (
      var day = moment($scope.momentStart);
      day <= $scope.momentStop;
      day.add(1, "days")
    ) {
      if (timeTravelMoment.isRedDayMoment(day) !== undefined) {
        //console.log('röd dag ',day);
        continue;
      }
      if (timeTravelMoment.isWeekendDay(day)) {
        //console.log('helg dag ',day);
        continue;
      }
      workHours += 8;
    }
    //console.log(workHours);

    Salary.get(
      {
        subTotal: $scope.subTotal,
        sum: $scope.sumMode,
        custMode: $scope.custMode,
        per: $scope.timeMode,
        type: $scope.groupMode,
        id: 0,
        from: $scope.momentStart.format("YYYY-MM-DD"),
        to: $scope.momentStop.format("YYYY-MM-DD"),
      },
      function (data) {
        $scope.expat = [];
        //spinnerService.hide('salaryReportSpinner1');
        for (var j = 0; j < data.length; j++) {
          try {
            data[j].reporteddate = $filter("date")(
              data[j].reporteddate,
              "yyyy-MM-dd"
            );
          } catch (error) {
            data[j].reporteddate = null;
          }
          data[j].periodlength = workHours;
          //data[j].kompppp = $scope.getKomp2(data[j].employee_id);
          //data[j].pattt = $scope.getKomp2(data[j].employee_id);

          $scope.expat[j] = [];

          $scope.kaka22($scope.header, data[j], $scope.expat[j]);
          $scope.expat[j][23] = [];
          for (var k = 0; k < data[j].saltrans.length; k++) {
            $scope.expat[j][23].push(JSON.parse(data[j].saltrans[k]));
          }
        }
        //console.log(workHours);
        //console.log(data);

        $scope.invoice = data;
        //console.log($scope.expat);
      }
    );

    if ($scope.groupMode === "T") {
      Salary.get(
        {
          subTotal: $scope.subTotal,
          sum: $scope.sumMode,
          custMode: { external: false, internal: false, absense: true },
          per: $scope.timeMode,
          type: "K",
          id: 0,
          from: $scope.momentStart.format("YYYY-MM-DD"),
          to: $scope.momentStop.format("YYYY-MM-DD"),
        },
        function (data) {
          //spinnerService.hide('salaryReportSpinner1');
          $scope.abscenseDetails = [];
          for (var j = 0; j < data.length; j++) {
            try {
              data[j].reporteddate = $filter("date")(
                data[j].reporteddate,
                "yyyy-MM-dd"
              );
            } catch (error) {
              data[j].reporteddate = null;
            }
            $scope.abscenseDetails.push(data[j]);
            /* if (data[j].activity_name !== "Uttag komptid") {
                $scope.abscenseDetails.push(data[j]);
              } */
            if (data[j].salary_id === null) {
              $scope.showAbscenseDetails = true;
            }
          }
          //console.log(workHours);
          //console.log(data);
        }
      );
    } else {
      $scope.abscenseDetails = [];
    }
  };

  $scope.salDel = function (salDetail, row) {
    var salDetails = [];
    console.log(salDetail);
    for (var j = 0; j < salDetail[23].length; j++) {
      var salRow = salDetail[23][j];
      console.log(salRow);
      salDetails.push(salRow);
    }
    FortNoxSalary.del(salDetails, function (resp) {
      salDetail[23][0] = null;
      console.log(resp);
    });
  };

  $scope.salAdd = function (salDetail, row) {
    //console.log(salDetail, $scope.exportData[row]);
    //        "EmployeeId": req.params.EmployeeId,
    //        "SalaryCode": req.params.SalaryCode,
    //        "Total": req.params.Total,
    //        "TextRow": req.params.TextRow,
    //        "Date": req.params.Date}

    var rorlig = {
      EmployeeId: salDetail[0],
      SalaryCode: 135,
      Total: $scope.expat[row][19],
      Date: $scope.salaryDates.stop.format("YYYY-MM-DD"),
      TextRow: "Rörlig",
    };
    //console.log(rorlig);

    salDetail[23] = [];

    if (rorlig.Total > 0) {
      FortNoxSalary.add(rorlig, function (resp) {
        salDetail[23].push(resp.SalaryTransaction);
        console.log(resp);
      });
    }

    var mert = angular.copy(rorlig);
    mert.Total = $scope.expat[row][20];
    mert.TextRow = "Mertid";
    if (mert.Total > 0) {
      FortNoxSalary.add(mert, function (resp) {
        salDetail[23].push(resp.SalaryTransaction);
        console.log(resp);
      });
    }

    var overt = angular.copy(rorlig);
    overt.Total = $scope.expat[row][21];
    overt.TextRow = "Övertid";
    if (overt.Total > 0) {
      FortNoxSalary.add(overt, function (resp) {
        salDetail[23].push(resp.SalaryTransaction);
        console.log(resp);
      });
    }
  };

  $scope.salAddAll = function (salDetailList) {
    var sal = [];
    var saldet = {};
    for (var j = 0; j < $scope.expat.length; j++) {
      //console.log(salDetailList[j]);

      saldet = {};
      if ($scope.expat[j][23][0] == null) {
        saldet.rorlig = {
          EmployeeId: $scope.expat[j][0],
          SalaryCode: 135,
          Total: $scope.expat[j][19],
          Date: $scope.salaryDates.stop.format("YYYY-MM-DD"),
          TextRow: "Rörlig",
        };

        saldet.mert = {
          EmployeeId: $scope.expat[j][0],
          SalaryCode: 135,
          Total: $scope.expat[j][20],
          Date: $scope.salaryDates.stop.format("YYYY-MM-DD"),
          TextRow: "Mertid",
        };

        saldet.overt = {
          EmployeeId: $scope.expat[j][0],
          SalaryCode: 135,
          Total: $scope.expat[j][21],
          Date: $scope.salaryDates.stop.format("YYYY-MM-DD"),
          TextRow: "Övertid",
        };
        sal.push(saldet);
      }
      //console.log(sal[j]);
    }
    FortNoxSalary.bulk(sal, function (resp) {
      for (var j = 0; j < resp.length; j++) {
        //console.log(resp[j]);
        if (
          "undefined" !== typeof resp[j][0] &&
          "undefined" !== typeof resp[j][0].SalaryTransaction
        ) {
          if ($scope.expat[j][23][0] == null) {
            $scope.expat[j][23][0] = resp[j][0].SalaryTransaction;
          } else {
            $scope.expat[j][23].push(resp[j][0].SalaryTransaction);
          }
        }
      }
    });

    $scope.showAbscenseDetails = false;
  };

  $scope.absCreate = function (absDetail) {
    //console.log(absDetail.employee_number + "," + absDetail.salary_code + "," + absDetail.time);

    var data = {
      EmployeeId: absDetail.employee_number,
      CauseCode: absDetail.salary_code,
      Date: absDetail.reporteddate,
      Hours: absDetail.time,
      RecordId: absDetail.record_id,
    };

    FortNoxAbsence.post(
      data,
      function (resp) {
        // OK uppdate transferred status
        absDetail.salary_id = absDetail.time;
      },
      function (err) {
        //console.log(err);
      }
    );
  };

  $scope.absCreateAll = function (absDetailList) {
    var abs = [];
    for (var j = 0; j < absDetailList.length; j++) {
      console.log(absDetailList[j].salary_id);
      if (absDetailList[j].salary_id !== null) {
        continue;
      }
      abs.push({
        EmployeeId: absDetailList[j].employee_number,
        CauseCode: absDetailList[j].salary_code,
        Date: absDetailList[j].reporteddate,
        Hours: absDetailList[j].time,
        RecordId: absDetailList[j].record_id,
      });
    }
    console.log(abs);

    function compareAbsence(absDetail, abs) {
      return (
        absDetail.reporteddate === abs.Date &&
        absDetail.employee_number == abs.EmployeeId &&
        absDetail.salary_code === abs.CauseCode
      );
    }

    FortNoxAbsence.bulk(abs, function (resp) {
      console.log(resp);
      var k = 0;
      for (var j = 0; j < resp.length; j++) {
        if ("undefined" !== typeof resp[j].fortnox.AbsenceTransaction) {
          while (
            !compareAbsence(
              absDetailList[k],
              resp[j].fortnox.AbsenceTransaction
            )
          ) {
            k++;
          }
          absDetailList[k].salary_id = 7;
        }
        k++;
      }
    });

    $scope.showAbscenseDetails = false;
  };

  $scope.absEdit = function (absDetail) {
    //console.log(absDetail.employee_number + "," + absDetail.salary_code + "," + absDetail.time);
  };

  $scope.absDel = function (absDetail) {
    //console.log(absDetail.employee_number + "," + absDetail.salary_code + "," + absDetail.time);

    var data = {
      EmployeeId: absDetail.employee_number,
      CauseCode: absDetail.salary_code,
      Date: absDetail.reporteddate,
      Hours: absDetail.time,
      RecordId: absDetail.record_id,
    };

    FortNoxAbsence.delete(data, function (resp) {
      absDetail.salary_id = null;
      //console.log(resp.body);
    });
  };

  $scope.kompUt = function (kompDetail) {
    //console.log(absDetail.employee_number + "," + absDetail.salary_code + "," + absDetail.time);

    var data = {
      EmployeeId: kompDetail.employee_number,
      CauseCode: kompDetail.salary_code,
      Date: kompDetail.reporteddate,
      Hours: kompDetail.time,
      RecordId: kompDetail.record_id,
    };
    FortNoxKomp.delete(data, function (resp) {
      kompDetail.salary_id = null;
      //console.log(resp.body);
    });
  };

  $scope.kompIn = function (kompDetail) {
    //console.log(absDetail.employee_number + "," + absDetail.salary_code + "," + absDetail.time);

    var data = {
      EmployeeId: kompDetail.employee_number,
      CauseCode: kompDetail.salary_code,
      Date: kompDetail.reporteddate,
      Hours: kompDetail.time,
      RecordId: kompDetail.record_id,
    };
    FortNoxKomp.delete(data, function (resp) {
      kompDetail.salary_id = null;
      //console.log(resp.body);
    });
  };
}
