angular
  .module("kitstajm")
  .factory("restServices", function () {
    var restServices = {};
    return restServices;
  })
  .factory("ProjectsActivities", function ($resource) {
    return $resource(
      "api/projectsactivities/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("Activity", function ($resource) {
    return $resource(
      "api//activities/new_price/:id",
      { id: "@id" },
      {
        update: { method: "PUT", isArray: false },
      }
    );
  })
  .factory("CustProjects", function ($resource) {
    return $resource(
      "api/customerprojects",
      {},
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("Timerecords", function ($resource) {
    return $resource(
      "api/timerecords/:id",
      { id: "@id" },
      {
        update: { method: "PUT" },
        insert: { method: "POST" },
        delete: { method: "DELETE" },
      }
    );
  })
  .factory("TimerecordsWeek", function ($resource) {
    return $resource(
      "api/timerecords/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("Invoice", function ($resource) {
    return $resource(
      "api/invoice/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("ReadInvoice", function ($resource) {
    return $resource(
      "api/readinvoice/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("InvoiceIt", function ($resource) {
    return $resource(
      "api/invoiceit/:cust_id/:from/:to",
      { cust_id: "@cust_id", from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
        insert: { method: "POST" },
      }
    );
  })
  .factory("InvoiceOp", function ($resource) {
    return $resource(
      "api/invoiceit/:statement_id/:invoice_number",
      { statement_id: "@statement_id", invoice_number: "@invoice_number" },
      {
        update: { method: "PUT" },
        remove: { method: "GET" },
        release: { method: "POST" },
      }
    );
  })
  .factory("InvoiceErp", function ($resource) {
    return $resource(
      "api/invoiceit/:statement_id/ext/:id",
      { statement_id: "@statement_id", id: "@id" },
      {
        update: { method: "PUT" },
      }
    );
  })
  .factory("TimeStats", function ($resource) {
    return $resource(
      "api/timestatistics/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("ProjSummary", function ($resource) {
    return $resource(
      "api/projsummary/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("ProjReport", function ($resource) {
    return $resource(
      "api/projreport/:id/:from/:to",
      { id: "@id", from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    ); //FortNoxAbsence
  })
  .factory("Salary", function ($resource) {
    return $resource(
      "api/salary/:id/:from/:to",
      { id: "@id", from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
        lock: { method: "POST", isArray: true },
      }
    ); //FortNoxAbsence
  })
  .factory("FortNoxAbsence", function ($resource) {
    return $resource(
      "api/absence",
      {},
      {
        post: { method: "POST", isArray: false },
        bulk: { method: "PUT", isArray: true },
      }
    );
  })
  .factory("FortNoxSalary", function ($resource) {
    return $resource(
      "api/salaryitem",
      {},
      {
        add: { method: "POST", isArray: false },
        bulk: { method: "PUT", isArray: true },
        del: { method: "DELETE", isArray: true, hasBody: true },
      }
    );
  })
  .factory("SalaryStatement", function ($resource) {
    return $resource(
      "api/salarystatement/:id",
      { id: "@id" },
      {
        get: { method: "GET", isArray: true },
        save: { method: "POST", isArray: true },
      }
    );
  })
  .factory("Report", function ($resource) {
    return $resource(
      "api/report/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("Vacation", function ($resource) {
    return $resource(
      "api/vacation/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: false },
      }
    );
  })
  .factory("KcList", function ($resource) {
    return $resource(
      "api/kclist",
      {},
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("SalesReports", function ($resource) {
    return $resource(
      "api/sales/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("KcReports", function ($resource) {
    return $resource(
      "api/kcreport/:id/:from/:to",
      { id: "@id", from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("Result", function ($resource) {
    return $resource(
      "api/result/:from/:to",
      { from: "@from", to: "@to" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("KompBank", function ($resource) {
    return $resource(
      "api/kompbank/:employee_id/:stop_date",
      { employee_id: "@employee_id", stop_date: "@stop_date" },
      {
        get: { method: "GET", isArray: true },
      }
    );
  })
  .factory("KompBank2", function ($resource) {
    return $resource(
      "api/kompbank2/:employee_id/:stop_date",
      { employee_id: "@employee_id", stop_date: "@stop_date" },
      {
        get: { method: "GET", isArray: true },
      }
    ); //getPdf
  })
  .factory("Erp", function ($resource) {
    return $resource(
      "api/erpInvoice/:id",
      { id: "@id" },
      {
        save: { method: "POST", isArray: false },
        update: { method: "PUT", isArray: false },
      }
    );
  })
  .factory("Pdf", function ($resource) {
    return $resource("api/getpdf/:id", { id: "id" }, {});
  })
  .factory("Salla", function ($resource) {
    return $resource("api/cleansalary", {}, {});
  })
  .factory("Aalla", function ($resource) {
    return $resource("api/cleanabsence", {}, {});
  })
  .factory("FortNoxKomp", function ($resource) {
    return $resource("api/komp", {}, {});
  })
  .factory("FortNoxAuth", function ($resource) {
    return $resource(
      "api/authcode",
      {},
      { save: { method: "POST", isArray: false } }
    );
  });
