/**
 * @module Config
 * @see Application
 * @param {Object} stateHelperProvider - Ui-router module for right nesting.
 * @param {Object} $urlRouterProvider - Configures how the application routing.
 * @param {Object} $locationProvider - Configures how the application deep linking paths are stored.
 * @param {Object} $logProvider - Configures how the application logs messages.
 */

import time from "./partial/time/time.html";
import about from "./partial/about/about.html";
import login from "./partial/login/login.html";
import logout from "./partial/logout/logout.html";
import invoice from "./partial/invoice/invoice.html";

import reports from "./partial/reports/reports.html";
import vacation from "./partial/vacation/vacation.html";
import salary from "./partial/salary/salary.html";
import salesReport from "./partial/salesReports/salesReports.html";
import kcReport from "./partial/kcReports/kcReports.html";
import projectReports from "./partial/projectReport/projectReport.html";
import projectSummaryReport from "./partial/projectSummaryReport/projectSummaryReport.html";
import timeStatisticsReport from "./partial/timeStatisticsReport/timeStatisticsReport.html";
import invoiceReports from "./partial/invoiceReports/invoiceReports.html";
import password from "./partial/password/password.html";
import kpi from "./partial/kpi/kpi.html";
import loginCallback from "./partial/loginCallback/loginCallback.html";
import admin from "./partial/admin/admin.html";
import employee from "./partial/admin/employee/employee.html";
import customer from "./partial/admin/customer/customer.html";
import project from "./partial/admin/project/project.html";
import activity from "./partial/admin/activity/activity.html";

const Config = (
  $stateProvider,
  $urlRouterProvider,
  $httpProvider,
  $locationProvider,
  $logProvider
) => {
  "ngInject";
  $httpProvider.interceptors.push("httpInterceptor");

  $logProvider.debugEnabled(true); /** Turn debug mode on/off */
  $locationProvider.html5Mode(true); /** Turn html5 mode on */
  $locationProvider.hashPrefix("");
  $urlRouterProvider.otherwise(
    "/time"
  ); /** If current route not in routes then redirect to home */

  /**
   * Url processing.
   * @param {Object} $injector - Ability to inject providers.
   * @param {Object} $location - Current location.
   */

  $urlRouterProvider.rule(($injector, $location) => {
    const path = $location.path();
    /** If route like as /home/ then /home */
    $location
      .path(path[path.length - 1] === "/" ? path.slice(0, -1) : path)
      .replace();
  });

  $stateProvider.state("about", {
    url: "/about",
    template: about,
  });
  $stateProvider.state("time", {
    url: "/time",
    template: time,
  });
  $stateProvider.state("login", {
    url: "/login",
    template: login,
  });
  $stateProvider.state("logout", {
    url: "/logout",
    template: logout,
  });
  $stateProvider.state("invoice", {
    url: "/invoice",
    template: invoice,
  });
  $stateProvider.state("reports", {
    url: "/reports",
    template: reports,
  });
  $stateProvider.state("vacation", {
    url: "/vacation",
    template: vacation,
  });
  $stateProvider.state("salary", {
    url: "/salary",
    template: salary,
  });
  $stateProvider.state("salesReports", {
    url: "/salesReports",
    template: salesReport,
  });
  $stateProvider.state("kcReports", {
    url: "/kcReports",
    template: kcReport,
  });
  $stateProvider.state("projectReport", {
    url: "/projectReport",
    template: projectReports,
  });
  $stateProvider.state("projectSummaryReport", {
    url: "/projectSummaryReport",
    template: projectSummaryReport,
  });
  $stateProvider.state("timeStatisticsReport", {
    url: "/timeStatisticsReport",
    template: timeStatisticsReport,
  });
  $stateProvider.state("invoiceReports", {
    url: "/invoiceReports",
    template: invoiceReports,
  });
  $stateProvider.state("password", {
    url: "/password",
    template: password,
  });
  $stateProvider.state("kpi", {
    url: "/kpi",
    template: kpi,
  });
  $stateProvider.state("loginCallback", {
    url: "/loginCallback",
    template: loginCallback,
  });
  $stateProvider.state("admin", {
    url: "/admin",
    views: {
      "": { template: admin, controller: "AdminCtrl as adminCtrl" },
      "emp@admin": {
        template: employee,
        controller: "EmployeeCtrl as employeeCtrl",
      },
      "cust@admin": {
        template: customer,
        controller: "CustomerCtrl as customerCtrl",
      },
      "proj@admin": {
        template: project,
        controller: "ProjectCtrl as projectCtrl",
      },
      "act@admin": {
        template: activity,
        controller: "ActivityCtrl as activityCtrl",
      },
    },
  });
};

/** Export our config */
export default Config;
